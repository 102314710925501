<template>
  <div class="hero-background-wrapper">
    <img src="../assets/images/hero-background.png" class="hero-background w-100" title="hero background"
      alt="hero-background" />
    <img src="../assets/images/haaland-pic.png" class="haaland d-none d-xl-block" />
    <img src="../assets/images/mbappe-pic.png" class="mbappe d-none d-xl-block" />
  </div>
  <div class="container">
    <div class="row justify-content-md-center">
      <div class="col-12 col-md-6 col-xl-5 login-wrapper">
        <figure class="text-center">
          <img src="@/assets/images/nav-top-logo.png" alt="app logo" style="width: 230px;" />
          <div class="mt-3">
            <strong class="text-light">
              {{ $t("GET_COIN_TODAY") }}
            </strong>
          </div>
        </figure>
        <div class="card card-custom mt-3">
          <div class="card-body p-0 m-0">
            <div class="my-3">
              <h6 class="pb-3 pt-1 ps-3 mb-2 pb-2 fw-semibold text-light custom-border-bottom">
                {{ $t("login_to_account") }}
              </h6>
              <div class="p-3">
                <form @submit.prevent="login">
                  <div class="form-floating mb-3">
                    <input type="text" class="form-control" placeholder="Username here ..." v-model="userData.username">
                    <label for="floatingInput">{{ $t("Email_Username") }}</label>
                  </div>
                  <div class="form-floating">
                    <input type="password" class="form-control" placeholder="Password here ..." v-model="userData.password">
                    <label for="floatingPassword">{{ $t("Password") }}</label>
                  </div>
                  <div class="row">
                    <div class="col-12 col-md-8">
                      <button type="submit" class="btn btn-primary text-center mt-4 py-3 w-100" >
                        <span>{{ $t("Login") }}</span>
                        <!-- <div class="spinner-grow spinner-grow-sm" role="status">
                          <span class="visually-hidden">Loading...</span>
                        </div> -->
                      </button>
                    </div>
                    <div class="col-12 col-md-4">
                      <router-link to="/Signup" type="submit" class="btn btn-dark text-center mt-4 py-3 w-100">
                        <span>{{ $t("Create_new_account") }}</span>
                      </router-link>
                    </div>
                  </div>
                  <div class="row mt-4">
                    <div class="col-12">
                      <router-link to="/ForgotPassword" type="submit" class="text-decoration-none text-light mt-4 py-3 w-100">
                        <span>{{ $t("Forgot_your_password")}}</span>
                      </router-link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CemeteryApi from '@/services/CemeteryApi';


export default {
  name: "Login",
  data() {
    return {
      userData:{
        username: "",
        password: ""
      }
    }
  },
  methods:{
    login(){
      CemeteryApi.login(this.userData)
      .then((res)=>{
        if(res.data.status){
          //TODO Login
          this.$store.commit("login", res.data.result.token);
          // this.$router.push("/");
          location.replace("/");
          this.$toast.success(res.data.result.message, {position: 'bottom'});
        }else{
          this.$toast.error(res.data.result.message, {position: 'bottom'});
        }
      })

    }
  },mounted(){
    // CemeteryApi.getConfig()
  }
};
</script>

<style scoped>
.login-wrapper {
  margin-top: 150px;
  z-index: 9 !important;
}

.header-background {
  height: 200px;
  background: linear-gradient(123.9deg, #11111E 0%, #1E1E2D 100%),
    linear-gradient(0deg, #232334, #232334);
  border-radius: 0 0 25px 25px;
}

.header-profile {
  position: absolute;
  top: 110px;
}
</style>