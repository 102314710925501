<template>
    <select  v-model="language" class="form-select"> 
        <option v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" :value="locale">{{ getLocalName(locale) }}</option>
    </select>
</template>
<script>
export default {
    data() {
        return {
            language:localStorage.getItem('language')? localStorage.getItem('language'): 'en',
        }
    },
    methods:{
        getLocalName(name){
            if(name=='en'){
                return 'English'
            }else{
                return 'العربی'
            }
        }
    },
    watch:{
        language(nval){
            this.$i18n.locale=nval;
            localStorage.setItem('language', nval)
            location.reload();

            // document.html.dir=nval!="en"? "rtl": "ltr";
            //TODO save nval in local storage and reload page
        }
    }
}
</script>