<template>
    <div class="container-fluid">
        <div class="row align-items-stretch g-4 py-4" v-show="userInfo.order_coin">
            <div class="col">
                <CardWidget :cardtitle="$t('Sold Coins')" :cardvalue="userInfo.order_coin" cardcolor="#8BC34A" cardicon="fal fa-check-square" />
            </div>
            <div class="col">
                <CardWidget :cardtitle="$t('Inventory')" :cardvalue="userInfo.wallet_amount" cardcolor="#66BB6A"
                    cardicon="fal fa-credit-card-blank" />
            </div>
            <div class="col">
                <CardWidget :cardtitle="$t('Loyalty')" :cardvalue="userInfo.bonus_percent" cardcolor="#d4692e" cardicon="fal fa-badge-percent" />
            </div>
            <div class="col">
                <CardWidget :cardtitle="$t('Loyalty reward')" :cardvalue="userInfo.bonus_amount" cardcolor="#d4692e"
                    cardicon="fal fa-treasure-chest" />
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card card-custom p-3">
                    <h4 class="text-light mt-1 pb-2">
                        {{$t('Request for deposit')}}
                    </h4>
                    <form class="row g-3">
                        <div class="row g-2">
                            <div class="col-12 col-md-3">
                                <label class="form-label text-light">{{$t('Requested amount')}}</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" v-model="financeRequest.amount">
                                    <button class="btn btn-primary" type="button" @click="submitFinanceRequest">{{$t('Submit Request')}}</button>
                                </div>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="Selectallinventory" v-model="selected">
                                <label class="form-check-label text-light form-check-label-margin" for="Selectallinventory">
                                    {{$t('Select all inventory')}}
                                </label>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card card-custom p-3">
                    <h4 class="text-light mt-1 pb-2">
                        {{$t('Wallet history')}}
                    </h4>
                    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link text-light active" id="pills-transactions-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-transactions" type="button" role="tab"
                                aria-controls="pills-transactions" aria-selected="true">{{$t('Wallet transactions')}}</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link text-light" id="pills-requests-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-requests" type="button" role="tab" aria-controls="pills-requests"
                                aria-selected="false">{{$t('Deposit requests')}}</button>
                        </li>
                    </ul>
                    <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade show text-light active" id="pills-transactions" role="tabpanel"
                            aria-labelledby="pills-transactions-tab" tabindex="0">
                            <table class="table table-dark table-striped">
                                <thead>
                                    <tr>
                                        <th>{{$t('Date')}}</th>
                                        <th>{{$t('Amount')}}</th>
                                        <th>{{$t('Reward')}}</th>
                                        <th>{{$t('description')}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item,index in wallet.list" :key="index">
                                        <td>{{ item.insert_time }}</td>
                                        <td>{{ item.increase_amount>0?item.increase_amount:item.decrease_amount }}</td>
                                        <td>
                                            <i
                                            :class="
                                                'fs-3 fal fa-' +
                                                (item.is_bonus
                                                ? 'gift text-success'
                                                : 'horizontal-rule text-secondary')
                                            "
                                            ></i>
                                        </td>
                                        <td>{{ item.description }}</td>
                                    </tr>
                                   
                                </tbody>
                            </table>
                            <nav aria-label="Page navigation example">
                                <ul class="pagination">
                                    <li class="page-item">
                                        <a
                                        class="page-link"
                                        @click="
                                            getWalletTransactionList(wallet.current_page > 1 ? wallet.current_page - 1 : wallet.current_page)
                                        "
                                        >
                                        {{ $t('Previous') }}
                                        </a>
                                    </li>
                                    <li
                                        v-for="index in getWalletIndexs"
                                        :key="index"
                                        :class="
                                        'page-item  mx-1 ' + (wallet.current_page == index ? 'active' : '')
                                        "
                                    >
                                        <a class="page-link" @click="getWalletTransactionList(index)">{{ index }}</a>
                                    </li>
                                    <li class="page-item">
                                        <a
                                        class="page-link"
                                        @click="
                                            getWalletTransactionList(
                                                wallet.current_page < wallet.last_page ? wallet.current_page + 1 : wallet.current_page
                                            )
                                        "
                                        >
                                        {{ $t('NEXT') }}
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div class="tab-pane text-light fade" id="pills-requests" role="tabpanel"
                            aria-labelledby="pills-requests-tab" tabindex="0">
                            <table class="table table-dark table-striped">
                                <thead>
                                    <tr>
                                        <th>{{$t('Date')}}</th>
                                        <th>{{$t('Amount')}}</th>
                                        <th>{{$t('State')}}</th>
                                        <th>{{$t('Tracking Code')}}</th>
                                        <th>{{$t('description')}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item,index in finance.list" :key="index">
                                        <td>{{ item.insert_time }}</td>
                                        <td>{{ item.amount }}</td>
                                        <td>{{ item.status_title }}</td>
                                        <td>{{ item.tracking_id }}</td>
                                        <td>{{ item.description }}</td>
                                    </tr>
                                </tbody>
                                <nav aria-label="Page navigation example">
                                <ul class="pagination">
                                    <li class="page-item">
                                        <a
                                        class="page-link"
                                        @click="
                                            getFinanceTransactionList(finance.current_page > 1 ? finance.current_page - 1 : finance.current_page)
                                        "
                                        >
                                        {{ $t('Previous') }}
                                        </a>
                                    </li>
                                    <li
                                        v-for="index in getFinanceIndexs"
                                        :key="index"
                                        :class="
                                        'page-item  mx-1 ' + (finance.current_page == index ? 'active' : '')
                                        "
                                    >
                                        <a class="page-link" @click="getFinanceTransactionList(index)">{{ index }}</a>
                                    </li>
                                    <li class="page-item">
                                        <a
                                        class="page-link"
                                        @click="
                                            getFinanceTransactionList(
                                                finance.current_page < finance.last_page ? finance.current_page + 1 : finance.current_page
                                            )
                                        "
                                        >
                                        {{ $t('NEXT') }}
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CardWidget from '@/components/dashboard/CardWidget.vue'
import CemeteryApi from '@/services/CemeteryApi';
export default {
    components: {
        CardWidget
    },
    data(){
        return {
            userInfo:{},
            finance:{
                list:[],
                current_page:1,
                last_page:1,
                total_count:0
            },
            financeRequest:{
                amount:''
            },
            selected:false,
            wallet:{
                list:[],
                current_page:1,
                last_page:1,
                total_count:0
            }
        }
    },
    /* eslint-disable */
    computed: {
        getWalletIndexs() {
            if (this.wallet.last_page > 5) {
                if (this.wallet.current_page < 5) {
                return Array.from({ length: 5 }, (_, index) => index + 1)
                } else if (this.wallet.current_page > 5) {
                    return Array.from(
                        { length: 10 },
                        (_, index) => index + 1 + this.wallet.current_page - 5
                    )
                }
            }else{
                return Array.from({ length: this.wallet.last_page }, (_, index) => index + 1)
            }
        },
        getFinanceIndexs() {
            if (this.finance.last_page > 5) {
                if (this.finance.current_page < 5) {
                return Array.from({ length: 5 }, (_, index) => index + 1)
                } else if (this.finance.current_page > 5) {
                    return Array.from(
                        { length: 10 },
                        (_, index) => index + 1 + this.finance.current_page - 5
                    )
                }
            }else{
                return Array.from({ length: this.finance.last_page }, (_, index) => index + 1)
            }
        }
    },
    methods:{
        submitFinanceRequest(){
            CemeteryApi.userFinanceRequest(this.financeRequest)
            .then((res)=>{
                if(res.data.status){
                    this.$toast.success(res.data.result.message, {position: 'bottom'});
                    this.financeRequest.amount=''
                    this.getProfileInfo()
                    this.getFinanceTransactionList(this.finance.current_page)
                    this.getWalletTransactionList(this.wallet.current_page)
                }else{
                    this.$toast.error(res.data.result.message, {position: 'bottom'});
                }
            })
        },
        getProfileInfo(){
            CemeteryApi.userProfileInfo()
            .then((res) => {
                if(res.data.status){
                    this.userInfo = res.data.result;
                }
            })
        },
        getFinanceTransactionList(page){
            const data={
                page:page
            }
            CemeteryApi.userFinanceTransactions(data)
            .then((res)=>{
                if(res.data.status){
                    this.finance.list=res.data.result.data;
                    this.finance.current_page=res.data.result.current_page
                    this.finance.total_count=res.data.result.total_count
                    this.finance.last_page=res.data.result.last_page
                }
            })
        },
        getWalletTransactionList(page){
            const data={
                page:page
            }
            CemeteryApi.userWalletTransactions(data)
            .then((res)=>{
                if(res.data.status){
                    this.wallet.list=res.data.result.data;
                    this.wallet.current_page=res.data.result.current_page
                    this.wallet.total_count=res.data.result.total_count
                    this.wallet.last_page=res.data.result.last_page
                }
            })
        }
    },
    watch:{
        selected(nval){
            if(nval){
                this.financeRequest.amount=this.userInfo.wallet_amount
            }else{
                this.financeRequest.amount=''
            }
        }
    },
    mounted(){
        this.getProfileInfo()
        this.getFinanceTransactionList(this.finance.current_page)
        this.getWalletTransactionList(this.wallet.current_page)
    }
}
</script>

<style></style>