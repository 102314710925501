<template>
    <DashboardContent />
    <CopyRight class="mt-5" />
</template>

<script>
import DashboardContent from '@/components/dashboard/DashboardContent.vue'
import CopyRight from '@/components/master/CopyRight.vue'
export default {
  components: {
    DashboardContent,
    CopyRight
  }
}
</script>

<style scoped>

</style>