import { createRouter, createWebHistory } from 'vue-router'
import Index from '@/views/IndexPage.vue'
import Login from '@/views/Login.vue'
import UserProfile from '@/views/UserProfile.vue'
import RequestCard from '@/views/RequestCard.vue'
import Wallet from '@/views/Wallet.vue'
import Loyalty from '@/views/Loyalty.vue'
import Faq from '@/views/Faq.vue'
import Order from '@/views/Order.vue'
import Signup from '@/views/Signup.vue'
import SignupCode from '@/views/SignupCode.vue'
import ForgotPassword from '@/views/ForgotPassword.vue'
import ForgotPasswordCode from '@/views/ForgotPasswordCode.vue'
import Setting from '@/views/Setting.vue'

import store from '@/store'
const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index,
    meta: { loginRequird: true }
  },
  {
    path: '/UserProfile',
    name: 'UserProfile',
    component: UserProfile,
    meta: { loginRequird: true }
  },
  {
    path: '/RequestCard',
    name: 'RequestCard',
    component: RequestCard,
    meta: { loginRequird: true }
  },
  {
    path: '/Wallet',
    name: 'Wallet',
    component: Wallet,
    meta: { loginRequird: true }
  },
  {
    path: '/Loyalty',
    name: 'Loyalty',
    component: Loyalty,
    meta: { loginRequird: true }
  },
  {
    path: '/Faq',
    name: 'Faq',
    component: Faq,
    meta: { loginRequird: true }
  },
  {
    path: '/Order',
    name: 'Order',
    component: Order,
    meta: { loginRequird: true }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: { loginRedirect: true }
  },
  {
    path: '/SignupCode',
    name: 'SignupCode',
    component: SignupCode,
    meta: { loginRedirect: true }
  },
  {
    path: '/Signup',
    name: 'Signup',
    component: Signup,
    meta: { loginRedirect: true }
  },
  {
    path: '/ForgotPassword',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: { loginRedirect: true }
  },
  {
    path: '/ForgotPasswordCode',
    name: 'ForgotPasswordCode',
    component: ForgotPasswordCode,
    meta: { loginRedirect: true }
  },
  {
    path: '/Setting',
    name: 'Setting',
    component: Setting,
    meta: { loginRequird: true }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {

    if (to.matched.some(record => record.meta.loginRequird)) {
      if (store.state.isAuthenticated) {
        next()
      } else {
        next('/login/')
      }
    } else if (to.matched.some(record => record.meta.loginRedirect)) {
      if (!store.state.isAuthenticated) {
        next()
      } else {
        next('/')
      }
    }
    else {
        next()
    }

})

export default router