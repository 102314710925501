<template>
    <div class="hero-background-wrapper">
    <img src="../assets/images/hero-background.png" class="hero-background w-100" title="hero background"
      alt="hero-background" />
    <img src="../assets/images/haaland-pic.png" class="haaland d-none d-xl-block" />
    <img src="../assets/images/mbappe-pic.png" class="mbappe d-none d-xl-block" />
  </div>
  <div class="container">
    <div class="row justify-content-md-center">
      <div class="col-12 col-md-6 col-xl-5 login-wrapper">
        <figure class="text-center">
          <img src="@/assets/images/nav-top-logo.png" alt="app logo" style="width: 230px;" />
          <div class="mt-3">
            <strong class="text-light">
              {{ $t('GET_COIN_TODAY') }}
            </strong>
          </div>
        </figure>
        <div class="card card-custom mt-3">
          <div class="card-body p-0 m-0">
            <div class="my-3">
              <h6 class="pb-3 pt-1 ps-3 mb-2 pb-2 fw-semibold text-light custom-border-bottom">
                {{$t('Forget password verification code')}}
              </h6>
              <div class="p-3">
                <form>
                  <div class="form-floating mb-3">
                    <input type="text" class="form-control" placeholder="Username here ...">
                    <label for="floatingInput">{{$t('Enter verification code')}}</label>
                  </div>
                  <div class="row">
                    <div class="col-12 col-md-8">
                      <button type="submit" class="btn btn-primary text-center mt-4 py-3 w-100">
                        <span>{{$t('Recover password')}}</span>
                        <!-- <div class="spinner-grow spinner-grow-sm" role="status">
                          <span class="visually-hidden">Loading...</span>
                        </div> -->
                      </button>
                    </div>
                    <div class="col-12 col-md-4">
                      <router-link to="/Login" class="btn btn-dark text-center mt-4 py-3 w-100">
                        <span>{{$t('Login')}}</span>
                      </router-link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.login-wrapper {
  margin-top: 150px;
  z-index: 9 !important;
}

.header-background {
  height: 200px;
  background: linear-gradient(123.9deg, #11111E 0%, #1E1E2D 100%),
    linear-gradient(0deg, #232334, #232334);
  border-radius: 0 0 25px 25px;
}

.header-profile {
  position: absolute;
  top: 110px;
}
</style>