<template>
    <UnderConstruction />
</template>

<script>
import UnderConstruction from '@/components/master/UnderConstruction.vue'
export default {
    components :{
        UnderConstruction
    }
}
</script>

<style>

</style>