import { createStore } from 'vuex'
import axios from 'axios'


export default createStore({
  state: {
    isAuthenticated: false,
    token: '',
    userType:'',
    language:'en'
  },
  getters: {
  },
  mutations: {
    onStart(state) {
      let token = localStorage.getItem('token')
      let lang = localStorage.getItem('language')
      // console.log("LANG : "+lang)

      if (token) {
        // login

        state.isAuthenticated = true
        state.token = token
        localStorage.setItem('token', token)
        axios.defaults.headers.common['Authorization'] = token

      } else {
        // logout

        state.isAuthenticated = false
        state.token = ''
        localStorage.removeItem('token')
        axios.defaults.headers.common['Authorization'] = ''
        delete axios.defaults.headers.common['Authorization']
        // console.log(state)
      }
      console.log("LANGGGGgg"+lang)
      axios.defaults.headers.common['Accept-Language']=lang
      // state.language=lang
    },
    login(state, token) {
      state.isAuthenticated = true
      state.token = token
      localStorage.setItem('token', token)
      axios.defaults.headers.common['Authorization'] = token
     
      
      // console.log('token : '+API.token)
    },
    logout(state) {
      state.isAuthenticated = false
      state.token = ''
      localStorage.removeItem('token')
      axios.defaults.headers.common['Authorization'] = ''
      delete axios.defaults.headers.common['Authorization']
    }
  },
  actions: {
    onStart(context) {
      // console.log(context)
      let token = localStorage.getItem('token')
      let lang = localStorage.getItem('language')
      if(!lang){
        localStorage.setItem('language','en')
        lang='en'
      }
      console.log("LANG : "+lang)
      if (token) {
        context.commit('login', token)
      }else{
        context.commit('logout')
      }
      console.log("LANGGGGgg"+lang)

      axios.defaults.headers.common['Accept-Language']=lang
      axios.defaults.headers.common['tagi']=lang
      this.state.language=lang
      console.log("LANGGGGgg"+lang)
    }
    
  },
  modules: {
  }
})
