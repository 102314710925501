<template>
  <MasterPage :loginState="true"></MasterPage>
</template>

<script>
import MasterPage from './views/MasterPage.vue';
import CemeteryApi from '@/services/CemeteryApi';
export default {
  name: 'FutSell',
  components: {
    MasterPage
  },
  methods:{
        getConfig(){
            CemeteryApi.getConfig()
            .then((res)=>{
                if(res.data.status){
                    this.appBaseConfig=res.data.result
                }else{
                    this.$toast.error(res.data.result.message, {position: 'bottom'});
                } 
            })
        }
    },
    mounted(){
        this.getConfig()
    }
}
</script>

<style>

</style>
