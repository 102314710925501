import axios from "axios";
import store from "@/store";
// import router from "@/router";
// import VueToast from 'vue-toast-notification';
// baseURL:'http://192.168.1.25:3000'
// baseURL:'https://tree.ardabilcity.ir'
const axiosInstance= axios.create({
    baseURL:'https://sell.eafc.shop/server/api/v3/',
    headers: {'Accept-Language': localStorage.getItem('language')},
});

axiosInstance.interceptors.request.use((config)=>{

    const token = store.state.token;
    if (token) {
        config.headers["Authorization"] = token
    }

    return config;
})

axiosInstance.interceptors.response.use(function(response){
    return response
},function(error){
    // this.$toast.error(error.response.status, {
    //     // override the global option
    //     position: "bottom",
    // });
    if(error.response.status==401){
        store.commit('logout')
        // router.push('/login')
    }
    console.log(error.response.status)
})

export default axiosInstance;