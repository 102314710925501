<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="card card-custom p-3">
                    <h4 class="text-light mt-1 pb-2">
                        {{$t('Edit profile information')}}
                    </h4>
                    <form class="row g-3" @submit.prevent="updateInfo">
                        <div class="col-12">
                            <label class="form-label text-light">{{$t('First name and last name')}}</label>
                            <input type="text" class="form-control" v-model="userInfo.fullname">
                        </div>
                        <div class="col-12">
                            <label class="form-label text-light">{{$t('PayPal Account Number (without spaces and dashes)')}}</label>
                            <input type="text" class="form-control" v-model="userInfo.paypal">
                        </div>
                        <div class="col-12">
                            <label class="form-label text-light">{{$t('Teter')}}</label>
                            <input type="text" class="form-control" v-model="userInfo.tether">
                        </div>
                        <div class="col-12">
                            <button type="submit" class="btn btn-primary py-2 px-4" >{{$t('Submit Changes')}}</button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="card card-custom p-3">
                    <h4 class="text-light mt-1 pb-2">
                        {{$t('Change password')}}
                    </h4>
                    <form class="row g-3" @submit.prevent="updatePassword">
                        <div class="col-12">
                            <label class="form-label text-light">{{$t('Current password')}}</label>
                            <input type="password" class="form-control" v-model="userPassword.old_password">
                        </div>
                        <div class="col-12">
                            <label class="form-label text-light">{{$t('Password')}}</label>
                            <input type="password" class="form-control" v-model="userPassword.new_password">
                        </div>
                        <div class="col-12">
                            <label class="form-label text-light">{{$t('Repeat password')}}</label>
                            <input type="password" class="form-control" v-model="userPassword.renew_password">
                        </div>
                        <div class="col-12">
                            <button type="submit" class="btn btn-primary py-2 px-4">{{$t('Submit Changes')}}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CemeteryApi from '@/services/CemeteryApi';

export default {
    data(){
        return{
            userInfo:{},
            userPassword:{}
        }
    },
    methods:{
        getUserInfo(){
            CemeteryApi.userProfileInfo()
            .then(res => this.userInfo = res.data.result);
        },
        updateInfo(){
            CemeteryApi.userProfileUpdate(this.userInfo)
            .then((res)=>{
                if(res.data.status){
                    this.getUserInfo()
                    this.$toast.success(res.data.result.message, {position: 'bottom'});
                    return
                }
                this.$toast.error(res.data.result.message, {position: 'bottom'});
            })
        },
        updatePassword(){
           CemeteryApi.userProfilePassword(this.userPassword)
           .then((res)=>{
                if(res.data.status){
                    this.getUserInfo()
                    this.$toast.success(res.data.result.message, {position: 'bottom'});
                    return
                }
                this.$toast.error(res.data.result.message, {position: 'bottom'});
            })

        }

    },
    mounted(){
        this.getUserInfo()
    }

}
</script>

<style></style>