<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card card-custom p-3">
                    <h4 class="text-light mt-1 pb-2">
                        {{$t('Registration of order to sell coins')}}
                    </h4>
                    <form class="row g-3" @submit.prevent="submitOrer">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-12 col-md-3">
                                    <label class="form-label text-light">{{$t('First name and last name')}} <span
                                            class="badge text-bg-danger d-inline-block ms-2 badge-dot"></span></label>
                                    <input type="text" class="form-control" v-model="order.fullname">
                                </div>
                                <div class="col-12 col-md-3">
                                    <label class="form-label text-light">{{$t('Email EA ')}}<span
                                            class="badge text-bg-danger d-inline-block ms-2 badge-dot"></span></label>
                                    <input type="text" class="form-control" v-model="order.email">
                                </div>
                                <div class="col-12 col-md-3">
                                    <label class="form-label text-light">{{$t('Password')}} <span
                                            class="badge text-bg-danger d-inline-block ms-2 badge-dot"></span></label>
                                    <input type="text" class="form-control" v-model="order.password">
                                </div>
                                <div class="col-12 col-md-3">
                                    <label class="form-label text-light">{{$t('Mobile Number')}}</label>
                                    <input type="text" class="form-control" v-model="order.mobile">
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="row">
                                <div class="col-12 col-md-3">
                                    <label class="form-label text-light">{{$t('Telegram')}}</label>
                                    <input type="text" class="form-control" v-model="order.telegram">
                                </div>
                                <div class="col-12 col-md-3">
                                    <label class="form-label text-light">{{$t('Console')}}<span
                                            class="badge text-bg-danger d-inline-block ms-2 badge-dot"></span></label>
                                    <select class="form-select" id="autoSizingSelect" v-model="order.console">
                                        <!-- <option selected>Choose Console ...</option> -->
                                        <option value="ps">PS/XBOX</option>
                                        <option value="pc">PC</option>
                                    </select>
                                </div>
                                <div class="col-12 col-md-3">
                                    <label class="form-label text-light">{{$t('Coin')}}<span
                                            class="badge text-bg-danger d-inline-block ms-2 badge-dot"></span></label>
                                    <input type="text" class="form-control" placeholder="For 100k, write 100" v-model="order.coin">
                                </div>
                                <div class="col-12 col-md-3">
                                    <label class="form-label text-light">{{$t('Description')}}</label>
                                    <input type="text" class="form-control" placeholder="Description..." v-model="order.description">
                                </div>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="row">
                                <div class="col-12 col-md-3">
                                    <label class="form-label text-light">{{$t('Backup Code 1')}}</label>
                                    <input type="text" class="form-control" v-model="order.backup_code_1">
                                </div>
                                <div class="col-12 col-md-3">
                                    <label class="form-label text-light">{{$t('Backup Code 2')}}</label>
                                    <input type="text" class="form-control" v-model="order.backup_code_2">
                                </div>
                                <div class="col-12 col-md-3">
                                    <label class="form-label text-light">{{$t('Backup Code 3')}}</label>
                                    <input type="text" class="form-control" v-model="order.backup_code_3">
                                </div>

                                <div class="col-12 col-md-3">
                                    <label class="form-label text-light">{{$t('authentication_secret')}}</label>
                                    <input type="text" class="form-control" v-model="order.authentication_secret">
                                </div>
                            </div>
                        </div>

                        <div class="col-12">
                            <button type="submit" class="btn btn-primary py-2 px-4">{{$t('Submit Changes')}}</button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-12">
                <div class="card card-custom p-3">
                    <h4 class="text-light mt-1 pb-2">
                        {{$t('Sales orders')}}
                    </h4>
                    <table class="table table-dark table-striped">
                        <thead>
                            <tr>
                                <th>{{$t('Console')}}</th>
                                <th>{{$t('Coin')}}</th>
                                <th>{{$t('State')}}</th>
                                <th>{{$t('Date')}}</th>
                                <th>{{$t('Detail')}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in list" :key="item.id">
                                <td>{{ item.console_title }}</td>
                                <td>{{ item.coin }}</td>
                                <td>{{ item.status_title }}</td>
                                <td>{{ item.insert_time }}</td>
                                <td>...</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CemeteryApi from '@/services/CemeteryApi';

export default {
    data(){
        return{
            list:[],
            order:{}
        }
    },
    methods:{
        getList(){
            CemeteryApi.userOrderWebappbazList()
            .then((res)=>{
                if(res.data.status){
                    this.list = res.data.result;
                }
            })
        },
        submitOrer(){
            CemeteryApi.userOrderWebappbaz(this.order)
            .then((res)=>{
                if(res.data.status){
                    this.order=={}
                    this.$toast.success(res.data.result.message, {position: 'bottom'});
                    this.getList()
                }else{
                    this.$toast.error(res.data.result.message, {position: 'bottom'});
                }
            });
        }
    },
    mounted(){
        this.getList()
    }
}
</script>

<style></style>