<template>
    <div class="container-fluid">
        <div class="accordion" id="accordionExample">
            <div class="accordion-item" v-for="item,index in list" :key="index">
                <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse'+index"
                        aria-expanded="true" aria-controls="collapseOne">
                        {{ item.title }}
                    </button>
                </h2>
                <div :id="'collapse'+index" class="accordion-collapse collapse" aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        <div v-html="item.content"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CemeteryApi from '@/services/CemeteryApi';

export default {
    data() {
        return {
            list:[]
        }
    },
    methods: {
        getList(){
            CemeteryApi.userFaq()
            .then((res)=>{
                if(res.data.status){
                    this.list = res.data.result;
                }
            })

        }
    },
    mounted(){
        this.getList()
    }

}
</script>

<style></style>