<template>
  <div class="text-center">
      <span class="opacity-75 text-light">
        Design and programming by
        <i class="fal fa-heart text-danger mx-1"></i>
        <a href="https://powergraph.ir/" class="text-light">
          Tadbirgaran Co.
        </a>
      </span>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>