<template>
    <SidebarMenu :menu="menu" :rtl="getDirection" :collapsed="collapsed" @update:collapsed="onToggleCollapse" @item-click="onItemClick">
        <template v-slot:header>
            <figure class="p-4 m-0">
                <img src="@/assets/images/nav-top-logo.png" class="w-100" alt="logo" title="logo" />
            </figure>
        </template>
    </SidebarMenu>
</template>

<script>
import { SidebarMenu } from 'vue-sidebar-menu'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
export default {
    components: {
        SidebarMenu
    },
    data() {
        return {
            innerWidth: 0,
            collapsed: true,
            lang:this.$i18n.locale,
            menu: [
                {
                    header: 'FutSell System',
                    hidden: false,
                    hiddenOnCollapse: true
                },
                {
                    href: '/',
                    title: this.$t('Dashboard'),
                    icon: 'fal fa-tachometer'
                },
                {
                    href: '/UserProfile',
                    title: this.$t('User profile'),
                    icon: 'fal fa-user'
                },
                {
                    href: '/RequestCard',
                    title: this.$t('Request a card'),
                    icon: 'fal fa-credit-card-blank'
                },
                {
                    href: 'Wallet',
                    title: this.$t('Wallet'),
                    icon: 'fal fa-wallet'
                },
                {
                    href: 'Loyalty',
                    title: this.$t('Loyalty and rewards'),
                    icon: 'fal fa-gift'
                },
                {
                    href: 'Faq',
                    title: this.$t('Frequently Questions'),
                    icon: 'fal fa-question'
                },
                {
                    href: 'Order',
                    title: this.$t('Coin'),
                    icon: 'fal fa-coin'
                },
                // {
                //     href: 'Setting',
                //     title: this.$t('Setting'),
                //     icon: 'fal fa-cog'
                // },
                {
                    title: this.$t("Sign Out"),
                    icon: 'fal fa-sign-out'
                }
            ]
        }
    },
    name: 'HelloWorld',
    props: {
        msg: String
    },
    computed:{
        getDirection(){
            return this.$store.state.language=="en"? false: true
        }
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        })
        this.onResize();
        
        setTimeout(() => {
            // console.log(this.$i18n.locale)
            this.menu= [
                {
                    header: 'FutSell System',
                    hidden: false,
                    hiddenOnCollapse: true
                },
                {
                    href: '/',
                    title: this.$t('Dashboard'),
                    icon: 'fal fa-tachometer'
                },
                {
                    href: '/UserProfile',
                    title: this.$t('User profile'),
                    icon: 'fal fa-user'
                },
                {
                    href: '/RequestCard',
                    title: this.$t('Request a card'),
                    icon: 'fal fa-credit-card-blank'
                },
                {
                    href: 'Wallet',
                    title: this.$t('Wallet'),
                    icon: 'fal fa-wallet'
                },
                {
                    href: 'Loyalty',
                    title: this.$t('Loyalty and rewards'),
                    icon: 'fal fa-gift'
                },
                {
                    href: 'Faq',
                    title: this.$t('Frequently Questions'),
                    icon: 'fal fa-question'
                },
                {
                    href: 'Order',
                    title: this.$t('Coin'),
                    icon: 'fal fa-coin'
                },
                // {
                //     href: 'Setting',
                //     title: this.$t('Setting'),
                //     icon: 'fal fa-cog'
                // },
                {
                    title: this.$t("Sign Out"),
                    icon: 'fal fa-sign-out'
                }
            ]
        }, 2);
    },
    methods: {
        onResize() {
            this.innerWidth = window.innerWidth;
            if (this.innerWidth < 600) {
                this.collapsed = true;
            }
            else {
                this.collapsed = false;
            }
        },
        onToggleCollapse(collapsed){
            this.emitter.emit("sidbarState",collapsed);
        },
        onItemClick(event,item){
            console.table(item)
            if (item.icon.includes('sign-out')) {
                this.exitUser()
            }
        },
        exitUser(){
        //show swif alert exit dialog
            this.$swal({
                title: this.$t('Exit'),
                text: this.$t('Are you sure you want to exit?'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#e2653f',
                cancelButtonColor: '#919191',
                confirmButtonText: this.$t('Yes'),
                cancelButtonText: this.$t('No')
            }).then((result) => {
                if (result.value) {
                this.$store.commit("logout")
                    // For Redirect After Logout
                    // this.$router.push("/login")
                    location.replace("/login");
                }
            })
        }   
    }
}
</script>

<style scoped>
.v-sidebar-menu {
    background-color: #11111E;
}
</style>