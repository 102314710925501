import axiosInstance from "./API"

export default{
    getUrl(){
        return axiosInstance.getUri()
    },
    getConfig(){
        return axiosInstance.get('user/config')
    },
    login(data){
        return axiosInstance.post('user/profile/login',data)
    },
    register(data){
        return axiosInstance.post('user/profile/register',data)
    },
    forget(data){
        return axiosInstance.post('user/profile/forget',data)
    },
    userProfileInfo(){
        return axiosInstance.get('user/profile/info')
    },
    userProfileUpdate(data){
        return axiosInstance.post('user/profile/update',data)
    },
    userProfilePassword(data){
        return axiosInstance.post('user/profile/password',data)
    },
   

    //User Order

    userOrderSubmit(data){
        return axiosInstance.post('user/order/submit',data)
    },
    userOrderList(data){
        return axiosInstance.post('user/order/list',data)
    },
    userOrderDetail(id){
        return axiosInstance.get('user/order/detail/'+id)
    },
    userOrderWebappbazList(){
        return axiosInstance.get('user/order/webappbaz/list')
    },
    userOrderBuy(id){
        return axiosInstance.get('user/order/buy/'+id)
    },
    userOrderWebappbaz(data){
        return axiosInstance.post('user/order/webappbaz',data)
    },

    // User Finance
    userFinanceTransactions(data){
        return axiosInstance.post("user/finance/transactions",data)
    },
    userFinanceRequest(data){
        return axiosInstance.post('user/finance/request',data)
    },

    // User Wallet
    userWalletTransactions(data){
        return axiosInstance.post("user/wallet/transactions",data)
    },

    userFaq(){
        return axiosInstance.get("user/faq")
    }

}