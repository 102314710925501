<template>
    <div class="hero-background-wrapper">
      <img src="../assets/images/hero-background.png" class="hero-background w-100" title="hero background"
        alt="hero-background" />
      <img src="../assets/images/haaland-pic.png" class="haaland d-none d-xl-block" />
      <img src="../assets/images/mbappe-pic.png" class="mbappe d-none d-xl-block" />
    </div>
    <div class="container">
      <div class="row justify-content-md-center">
        <div class="col-12 col-md-6 col-xl-5 login-wrapper">
          <figure class="text-center">
            <img src="@/assets/images/nav-top-logo.png" alt="app logo" style="width: 230px;" />
            <div class="mt-3">
              <strong class="text-light">
                {{ $t('GET_COIN_TODAY') }}
              </strong>
            </div>
          </figure>
          <div class="card card-custom mt-3">
            <div class="card-body p-0 m-0">
              <div class="my-3">
                <h6 class="pb-3 pt-1 ps-3 mb-2 pb-2 fw-semibold text-light custom-border-bottom">
                    {{$t('Forgot account Password')}}
                </h6>
                <div class="p-3">
                  <form @submit.prevent="login">
                    <div class="form-floating mb-3" v-if="state=='forgot'">
                      <input type="text" class="form-control" placeholder="Username here ..." v-model="userData.username">
                      <!-- <label for="floatingInput">{{$('Email_Username')}}</label> -->
                      <label for="floatingInput">Email</label>
                    </div>

                    <div class="form-floating mb-3" v-if="state!='forgot'">
                      <input type="password" class="form-control" placeholder="Password here ..." v-model="userData.code">
                      <label for="floatingPassword">Code</label>
                    </div>
                      
                    <div class="row">
                      <div class="col-12 col-md-8">
                        <button class="btn btn-primary text-center mt-4 py-3 w-100" type="submit" @click="foregot">
                          <span>{{$t('Recover Password')}}</span>
                        </button>
                      </div>
                      <div class="col-12 col-md-4">
                        <router-link to="/Login" type="submit" class="btn btn-dark text-center mt-4 py-3 w-100">
                          <span>{{$t('Login')}}</span>
                        </router-link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import CemeteryApi from '@/services/CemeteryApi';
  export default {
    name: "Forgot Password",
    data() {
      return {
        state:'forgot',
        buttoText:'Recover Password',
        userData:{
          username: "",
          code:""
        }
      }
    },
    methods:{
      foregot(){
        if (this.userData.username.length <= 3) {
          this.$toast.warning('username is very short', {position: 'bottom'});
          return
        }

        CemeteryApi.forget(this.userData)
        .then((res)=>{
          if(res.data.status){
            //TODO Login
            // this.$store.commit("login", res.data.result.token);
            if(this.state=='forgot'){
              this.state='verify'
              this.buttoText='Verify'
            }else{
              // this.$store.commit("login", res.data.result.token);
              location.replace("/");
            }
            // this.$router.push("/SignupCode");
            // location.replace("/");
            this.$toast.success(res.data.result.message, {position: 'bottom'});
          }else{
            this.$toast.error(res.data.result.message, {position: 'bottom'});
          }
        })

    }
    }
  };
  </script>
  
  <style scoped>
  .login-wrapper {
    margin-top: 150px;
    z-index: 9 !important;
  }
  
  .header-background {
    height: 200px;
    background: linear-gradient(123.9deg, #11111E 0%, #1E1E2D 100%),
      linear-gradient(0deg, #232334, #232334);
    border-radius: 0 0 25px 25px;
  }
  
  .header-profile {
    position: absolute;
    top: 110px;
  }
  </style>