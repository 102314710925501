<template>
    <div class="card card-cover card-custom card-custom-border h-100 overflow-hidden text-bg-white shadow-sm"
        :style="{ 'background-color': cardcolor }">
        <div class="h-100 p-5 pb-3 text-dark shadow-sm d-block">
            <div class="card-icon float-end mb-3">
                <i :class="cardicon" class="text-light fw-lighter"></i>
            </div>
            <div class="float-start">
                <h1 class="text-light text-light card-title">
                    {{ cardtitle }}
                </h1>
                <h2 class="mt-3 mb-4 display-5 lh-1 fw-semibold text-custom-orange">
                    {{ cardvalue }}
                </h2>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['cardtitle', 'cardvalue', 'cardcolor', 'cardicon']
}
</script>

<style>
.card-icon i {
    font-size: 50px;
    opacity: 0.3;

}
</style>