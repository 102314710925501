<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card card-custom p-3">
                    <h4 class="text-light mt-1 pb-2">
                        Request a card
                    </h4>
                    <form class="row g-3" @submit.prevent="submitOrder">
                        <div class="col-12 col-md-3" v-if="config.player_action">
                            <label class="form-label text-light">{{$t('Card type')}}</label>
                            <select class="form-select" id="autoSizingSelect" v-model="request_card.type">
                                <option value="0" selected>{{$t('Choose Card type ...')}}</option>
                                <option :value="config.player_action.safe">{{ config.player_action.safe.title }}</option>
                                <option :value="config.player_action.risky">{{ config.player_action.risky.title }}</option>
                            </select>
                        </div>
                        <div class="col-12 col-md-3" v-if="request_card.type!=0">
                            <label class="form-label text-light">{{$t('Console')}}</label>
                            <select class="form-select" id="autoSizingSelect" v-model="request_card.platform">
                                <option value="0" selected>{{$t('Choose Console ...')}}</option>
                                <option :value="request_card.type.platform.ps">{{ request_card.type.platform.ps.title }}</option>
                                <option :value="request_card.type.platform.pc">{{ request_card.type.platform.pc.title }}</option>
                            </select>
                        </div>
                        <div class="row g-2">
                            <div class="col-12 col-md-3">
                                <label class="form-label text-light">Inventory</label>
                                <input type="text" class="form-control" placeholder="For 100k, write 100" v-model="request_card.coin">
                            </div>
                            <div class="col-12 col-md-3">
                                <label class="form-label text-light">{{$t('Limit the number of active requests')}}</label>
                                <input type="text" class="form-control text-center" disabled value="2">
                            </div>
                            <div class="col-12 col-md-3">
                                <label class="form-label text-light">{{$t('Minimum inventory request')}}</label>
                                <input type="text" class="form-control text-center" disabled :value="request_card.platform.min_limit">
                            </div>
                        </div>
                        <div class="row g-2">
                            <div class="col-12">
                                <button type="submit" class="btn btn-primary py-2 px-4">{{$t('Submit')}}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card card-custom p-3">
                    <h4 class="text-light mt-1 pb-2">
                        {{$t('Cards')}}
                    </h4>
                    <table class="table table-dark table-striped table-responsive">
                        <thead>
                            <tr>
                                <th>{{$t('State')}}</th>
                                <th>{{$t('Platform')}}</th>
                                <th>"{{$t('Type')}}"</th>
                                <th>{{$t('Value')}}</th>
                                <th>{{$t('Net payable')}}</th>
                                <th>{{$t('Date of Registration')}}</th>
                                <th>{{$t('Detail')}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in list" :key="item.id">
                                <td>{{ item.status_title }}</td>
                                <td>{{ item.platform }}</td>
                                <td>{{ item.type_title }}</td>
                                <td>{{ item.coin_amount }}</td>
                                <td>{{ item.wallet_increase }}</td>
                                <td>{{ item.insert_time }}</td>
                                <td>
                                    <!-- Button trigger modal -->
                                    <button type="button" class="btn btn-primary" data-bs-toggle="modal" v-if="item.status!='expired'"
                                        data-bs-target="#exampleModal" @click="getCardInfo(item.id)">
                                        {{$t('Card detail')}}
                                    </button>
                                    <!-- Modal -->

                                    <div class="modal modal-lg fade" id="exampleModal" tabindex="-1"
                                        aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div class="modal-dialog">
                                            <div class="modal-content"  v-if="cardInfo.player">
                                                <div class="modal-header">
                                                    <h1 class="modal-title fs-5 text-dark" id="exampleModalLabel">{{ cardInfo.player.name }}</h1>
                                                    <button type="button" class="btn-close" data-bs-dismiss="modal" id="close_card_modal"
                                                        aria-label="Close"></button>
                                                </div>
                                                <div class="modal-body">
                                                    <figure class="text-center player-card mx-auto"
                                                        :style="'background-image: url(' + cardInfo.player.bg_image + ')'"
                                                        >
                                                        <div class="player-card-top">
                                                            <div class="player-master-info">
                                                                <div class="player-rating textclass">
                                                                    <span>{{ cardInfo.player.rating}}</span>
                                                                </div>
                                                                <div class="player-position textclass">
                                                                    <span>{{  cardInfo.player.position }}</span>
                                                                </div>
                                                                <div class="player-position textclass">
                                                                    <span>
                                                                        <img :src="getPlatformIcon(cardInfo.platform)"
                                                                            class="platform-ico" alt="platform icon"
                                                                            title="platform icon" />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div class="player-picture">
                                                                <img :src="cardInfo.player.image"
                                                                    alt="Messi" draggable="false">
                                                                <div class="player-extra"></div>
                                                            </div>
                                                        </div>
                                                        <div class="player-card-bottom">
                                                            <div class="player-info">
                                                                <div class="player-name textclass">
                                                                    <span>{{ cardInfo.player.name }}</span>
                                                                </div>
                                                                <div class="player-features textclass">
                                                                    <div class="player-features-col">
                                                                        <span>
                                                                            <div class="player-feature-title me-2">DRI</div>
                                                                            <div class="player-feature-value">{{ cardInfo.player.dri  }}</div>
                                                                        </span>
                                                                        <span>
                                                                            <div class="player-feature-title me-2">DEF</div>
                                                                            <div class="player-feature-value">{{cardInfo.player.def}}</div>
                                                                        </span>
                                                                        <span>
                                                                            <div class="player-feature-title me-2">PHY</div>
                                                                            <div class="player-feature-value">{{cardInfo.player.phy}}</div>
                                                                        </span>
                                                                    </div>
                                                                    <div class="player-features-col">
                                                                        <span>
                                                                            <div class="player-feature-title me-2">PAC</div>
                                                                            <div class="player-feature-value">{{cardInfo.player.pac}}</div>
                                                                        </span><span>
                                                                            <div class="player-feature-title me-2">SHO</div>
                                                                            <div class="player-feature-value">{{cardInfo.player.sho}}</div>
                                                                        </span><span>
                                                                            <div class="player-feature-title me-2">PAS</div>
                                                                            <div class="player-feature-value">{{cardInfo.player.pas}}</div>
                                                                        </span></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </figure>
                                                    <table class="table table-dark table-striped table-responsive">
                                                        <tbody>
                                                            <tr>
                                                                <td style="width: 50%;">
                                                                    {{$t('Your time for cards')}}
                                                                </td>
                                                                <td style="width: 50%;">
                                                                    {{$t('Card expire (Duration)')}}
                                                                </td>
                                                            </tr>
                                                            <tr v-for="item in cardInfo.player.extra_data" :key="item.key">
                                                                <td
                                                                    v-for="p in item"
                                                                    :key="p.id"
                                                                    :colspan="item.length == 1 ? 2 : 1"
                                                                    >
                                                                    <span>{{ p.key }}</span>
                                                                    <span class="badge text-bg-light float-end fw-light me-3">
                                                                        {{ p.value }}
                                                                    </span>
                                                                </td>

                                                            </tr>
                            
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-primary" @click="buySubmition()"
                                                        data-bs-dismiss="modal">{{$t('I bought')}}</button>
                                                    <button type="button" class="btn btn-secondary" @click="copyCardInfo()">{{$t('Copy card information')}}
                                                        <textarea
                                                            style="display: none"
                                                            type="hidden"
                                                            id="testing-code"
                                                            :value="copyedData"
                                                        ></textarea>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <nav aria-label="Page navigation example">
                        <ul class="pagination">
                            <li class="page-item">
                                <a
                                class="page-link"
                                @click="
                                    getList(current_page > 1 ? current_page - 1 : current_page)
                                "
                                >
                                {{ $t('Previous') }}
                                </a>
                            </li>
                            <li
                                v-for="index in getIndexs"
                                :key="index"
                                :class="
                                'page-item  mx-1 ' + (current_page == index ? 'active' : '')
                                "
                            >
                                <a class="page-link" @click="getList(index)">{{ index }}</a>
                            </li>
                            <li class="page-item">
                                <a
                                class="page-link"
                                @click="
                                    getList(
                                    current_page < last_page ? current_page + 1 : current_page
                                    )
                                "
                                >
                                {{ $t('NEXT') }}
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import CemeteryApi from '@/services/CemeteryApi';
export default {
    computed: {
        getIndexs() {
            if (this.last_page > 5) {
                if (this.current_page < 5) {
                return Array.from({ length: 5 }, (_, index) => index + 1)
                } else if (this.current_page > 5) {
                return Array.from(
                    { length: 10 },
                    (_, index) => index + 1 + this.current_page - 5
                )
                }
            }else{
                return Array.from({ length: this.last_page }, (_, index) => index + 1)
            }
        }
    },
    data(){
        return{
            config:{},
            request_card:{
                type:0,
                platform:0
            },
            list:[],
            cardInfo:{},
            copyedData:'',
            current_page:1,
            last_page:1,
            total_count:0
        }
    },
    methods:{
        buySubmition(){
            CemeteryApi.userOrderBuy()
            .then((res)=>{
                if(res.data.status){
                    this.$toast.success(res.data.result.message, {position: 'bottom'});
                    document.getElementById("close_card_modal").click()
                }else{
                    this.$toast.error(res.data.result.message, {position: 'bottom'});
                }
            })
        },
        copyCardInfo() {
        // this.createCopyData()

            let testingCodeToCopy = document.querySelector('#testing-code')
            testingCodeToCopy.setAttribute('type', 'text')
            testingCodeToCopy.style.display = 'block'
            testingCodeToCopy.select()

            try {
                var successful = document.execCommand('copy')
                testingCodeToCopy.style.display = 'none'
                var msg = successful
                ? 'اطلاعات کارت با موفقیت کپی شد'
                : 'خطا در کپی اطلاعات'
                this.$toast.success(msg, {
                // override the global option
                position: 'bottom-left'
                })
            } catch (err) {
                console.log('Oops, unable to copy')
            }

            /* unselect the range */
            testingCodeToCopy.setAttribute('type', 'hidden')
            window.getSelection().removeAllRanges()
        },
        getPlatformIcon(platform){
            if(platform=="ps"){
                return require('@/assets/images/playstation.png')
            }else if(platform=="pc"){
                return require('@/assets/images/pc.png')
            }else{
                return require('@/assets/images/xbox.png')
            }
        },
        getConfig(){
            CemeteryApi.getConfig()
            .then((res)=>{
                if(res.data.status){
                    this.config=res.data.result
                }else{
                    this.$toast.error(res.data.result.message, {position: 'bottom'});
                } 
            })
        },
        getCardInfo(id){
            CemeteryApi.userOrderDetail(id)
            .then((res)=>{
                if(res.data.status){
                    this.cardInfo=res.data.result
                    this.copyedData=this.cardInfo.player.copy_data
                }
            })
        },
        submitOrder(){
            CemeteryApi.userOrderSubmit(
                {
                    coin:this.request_card.coin,
                    type:this.request_card.type.title,
                    platform:this.request_card.platform.title
                }
            ).then((res)=>{
                if(res.data.status){
                    this.$toast.success(res.data.result.message, {position: 'bottom'});
                    return
                }
                this.$toast.error(res.data.result.message, {position: 'bottom'});
            })
        },
        getList(page){
            const data={
                page:page
            }
            CemeteryApi.userOrderList(data)
            .then((res)=>{
                if(res.data.status){
                    this.list=res.data.result.data;
                    this.current_page=res.data.result.current_page
                    this.total_count=res.data.result.total_count
                    this.last_page=res.data.result.last_page
                }
            })
        }
    },
    mounted(){
        this.getConfig()
        this.getList(this.current_page)
    }
}
</script>

<style scoped>
.player-card {
    position: relative;
    width: 300px;
    height: 485px;
    background-position: 50%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 3.8rem 0;
    z-index: 2;
    transition: .2s ease-in;
}

.player-card .player-card-top {
    position: relative;
    display: flex;
    color: #e9cc74;
    padding: 0 1.5rem;
}

.player-card .player-card-top {
    position: relative;
    display: flex;
    color: #e9cc74;
    padding: 0 1.5rem;
}

.player-card .player-card-top .player-master-info {
    position: absolute;
    line-height: 2.2rem;
    left: 20px;
    text-align: left;
    font-weight: 300;
    padding: 1.5rem 0;
    text-transform: uppercase;
}

.player-card .player-card-top .player-master-info .player-rating span {
    font-size: 45px;
    text-shadow: 1px 1px 2px black;
    color: white;
}

.player-card .player-card-top .player-master-info .player-position span {
    font-size: 30px;
    text-shadow: 1px 1px 2px black;
    color: white;
}
.platform-ico {
    width: 40px;
}
.player-card .player-card-top .player-picture {
    width: 200px;
    height: 200px;
    margin: 0 auto;
    overflow: hidden;
}

.player-card .player-card-bottom {
    position: relative;
}
.player-card .player-card-bottom .player-info {
  display: block;
  padding: .3rem 0;
  color: #FFF;
  width: 90%;
  margin: 0 auto;
  height: auto;
  position: relative;
  z-index: 2;
}
.player-card .player-card-bottom .player-info .player-name {
  width: 100%;
  display: block;
  text-align: center;
  text-transform: uppercase;
  border-bottom: 2px solid rgba(1,1,116,.1);
  padding-bottom: .3rem;
  overflow: hidden;
}
.player-card .player-card-bottom .player-info .player-name span {
  display: block;
  text-shadow: 1px 1px 2px black;
  font-size: 28px;
}
.player-card .player-card-bottom .player-info .player-features {
  margin: .5rem auto;
  display: flex;
  justify-content: center;
}
.player-card .player-card-bottom .player-info .player-features .player-features-col {
  padding: 0 2.3rem;
}
.player-card .player-card-bottom .player-info .player-features .player-features-col span {
    display: block ruby;
font-size: 1.2rem;
text-transform: uppercase;
}
.player-card .player-card-bottom .player-info .player-features .player-features-col span .player-feature-title {
  font-weight: 300;
  font-size: 20px;
  text-shadow: 1px 1px 2px black;
}
.player-card .player-card-bottom .player-info .player-features .player-features-col span .player-feature-value {
  margin-right: .3rem;
  font-weight: 700;
  font-size: 23px;
  text-shadow: 1px 1px 2px black;
}
.player-card .player-card-top .player-picture img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  position: relative;
  right: -1.5rem;
  bottom: 0;
}
</style>