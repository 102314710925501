<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card card-custom p-3">
                    <h4 class="text-light mt-1 pb-2">
                        Setting
                    </h4>
                    <form class="row g-3">
                        <div class="col-12 col-md-3">
                            <label class="form-label text-light">Language</label>
                            <select class="form-select" id="autoSizingSelect">
                                <option selected>Choose Language ...</option>
                                <option value="1">English</option>
                                <option value="2">Arabic</option>
                            </select>
                        </div>
                    
                        <div class="row g-2">
                            <div class="col-12">
                                <button type="submit" class="btn btn-primary py-2 px-4">Submit settings</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>