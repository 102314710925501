<template>
    <div class="under-construction">
        <figure class="text-center">
            <img src="@/assets/images/under_construction_en.png" class="under-construction-image" alt="comming soon" title="comming soon" />
        </figure>
    </div>
</template>

<script>
export default {

}
</script>

<style></style>