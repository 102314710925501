import { createApp } from 'vue'
import App from './App.vue'
import router from './router/router.js'
import store from './store'
// import axios from 'axios'

// bootstrap
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import 'bootstrap/dist/css/bootstrap.min.css' 
import 'bootstrap/dist/css/bootstrap.rtl.min.css'

// i Custom Style
import './assets/css/custom.css'
// i Fonts
import './assets/css/fonts.css'
import './assets/fonts/fontawesome-pro-5.2.0/css/fontawesome.min.css'
import './assets/fonts/fontawesome-pro-5.2.0/css/light.min.css'


import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import mitt from 'mitt';
import i18n from '@/utils/i18n.js'
import axios from 'axios'



const globals={
    data(){
        return {
            appBaseConfig:{
                currency:{
                    symbol_left:'$',
                    pattern:'en-US',
                    symbol_right:''
                }
            } 
        }
    },
    methods:{
        getPrice(currency){
            // this.appBaseConfig.currency.locale='en-US'
            var usFormat = currency.toLocaleString(this.appBaseConfig.currency.locale)
            return this.appBaseConfig.currency.symbol_left+' '+usFormat+' '+this.appBaseConfig.currency.symbol_right
        }
    }
}


const emitter = mitt();
const app = createApp(App)
app.config.globalProperties.emitter = emitter
app.use(router)
.use(store)
.use(VueToast)
.use(i18n)
.use(VueSweetalert2)
.mixin(globals)
.mount('#app')

