<template>
    <div class="container-fluid">
        <div class="row align-items-stretch g-4 py-4">
            <div class="col">
                <CardWidget  :cardtitle="$t('Wallet')" :cardvalue="getPrice(userInfo.wallet_amount)" cardcolor="#8BC34A" cardicon="fal fa-wallet" />
            </div>
            <div class="col">
                <CardWidget :cardtitle="$t('active_order')" :cardvalue="userInfo.active_order" cardcolor="#66BB6A"
                    cardicon="fal fa-credit-card-blank" />
            </div>
            <div class="col">
                <CardWidget :cardtitle="$t('Coin')" :cardvalue="userInfo.order_coin" cardcolor="#d4692e" cardicon="fal fa-coin" />
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-md-6">
                <div class="card card-custom p-3">
                    <h4 class="text-light mt-1 pb-2">
                        {{$t('Player action pricing')}}
                    </h4>
                    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link text-light active" id="pills-safe-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-safe" type="button" role="tab" aria-controls="pills-safe"
                                aria-selected="true">{{$t('Safe')}}</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link text-light" id="pills-risky-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-risky" type="button" role="tab" aria-controls="pills-risky"
                                aria-selected="false">{{$t('Risky')}}</button>
                        </li>
                    </ul>
                    <div class="tab-content" id="pills-tabContent" v-if="config.player_action">
                        <div class="tab-pane fade show text-light active" id="pills-safe" role="tabpanel"
                            aria-labelledby="pills-safe-tab" tabindex="0">
                            <table class="table table-dark table-striped" >
                                <thead>
                                    <tr>
                                        <th>{{$t('Platform')}}</th>
                                        <th>{{$t('Price')}}</th>
                                        <th>{{$t('State')}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{{ config.player_action.safe.platform.pc.title }}</td>
                                        <td>{{ config.player_action.safe.platform.pc.price }}</td>
                                        <td>{{ config.player_action.safe.platform.pc.status }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{ config.player_action.safe.platform.ps.title }}</td>
                                        <td>{{ config.player_action.safe.platform.ps.price }}</td>
                                        <td>{{ config.player_action.safe.platform.ps.status }}</td>
                                    </tr>
                                    <!-- <tr>
                                        <td>...</td>
                                        <td>...</td>
                                        <td>...</td>
                                    </tr> -->
                                </tbody>
                            </table>
                        </div>
                        <div class="tab-pane text-light fade" id="pills-risky" role="tabpanel" aria-labelledby="pills-risky-tab"
                            tabindex="0">
                            <table class="table table-dark table-striped" >
                                <thead>
                                    <tr>
                                        <th>{{$t('Platform')}}</th>
                                        <th>{{$t('Price')}}</th>
                                        <th>{{$t('State')}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{{ config.player_action.risky.platform.pc.title }}</td>
                                        <td>{{ config.player_action.risky.platform.pc.price }}</td>
                                        <td>{{ config.player_action.risky.platform.pc.status }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{ config.player_action.risky.platform.ps.title }}</td>
                                        <td>{{ config.player_action.risky.platform.ps.price }}</td>
                                        <td>{{ config.player_action.risky.platform.ps.status }}</td>
                                    </tr>
                                    <!-- <tr>
                                        <td>...</td>
                                        <td>...</td>
                                        <td>...</td>
                                    </tr> -->
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="card card-custom p-3">
                    <h4 class="text-light mt-1 pb-2">
                        {{$t('Web app baz pricing')}}
                    </h4>
                    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link text-light active" id="pills-safe-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-safe-wab" type="button" role="tab" aria-controls="pills-safe"
                                aria-selected="true">{{$t('Safe')}}</button>
                        </li>
                        <!-- <li class="nav-item" role="presentation">
                            <button class="nav-link text-light" id="pills-risky-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-risky-wab" type="button" role="tab" aria-controls="pills-risky"
                                aria-selected="false">{{$t('Risky')}}</button>
                        </li> -->
                    </ul>
                    <div class="tab-content" id="pills-tabContent" v-if="config.webappbaz">
                        <div class="tab-pane fade show text-light active" id="pills-safe-wab" role="tabpanel"
                            aria-labelledby="pills-safe-tab" tabindex="0">
                            <table class="table table-dark table-striped">
                                <thead>
                                    <tr>
                                        <th>{{$t('Platform')}}</th>
                                        <th>{{$t('Price')}}</th>
                                        <th>{{$t('State')}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{{ config.webappbaz.platform.pc.title }}</td>
                                        <td>{{ config.webappbaz.platform.pc.price }}</td>
                                        <td>{{ config.webappbaz.platform.pc.status }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{ config.webappbaz.platform.ps.title }}</td>
                                        <td>{{ config.webappbaz.platform.ps.price }}</td>
                                        <td>{{ config.webappbaz.platform.ps.status }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- <div class="tab-pane text-light fade" id="pills-risky-wab" role="tabpanel" aria-labelledby="pills-risky-tab"
                            tabindex="0">
                            <table class="table table-dark table-striped">
                                <thead>
                                    <tr>
                                        <th>{{$t('Platform')}}</th>
                                        <th>{{$t('Price')}}</th>
                                        <th>{{$t('State')}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>...</td>
                                        <td>...</td>
                                        <td>...</td>
                                    </tr>
                                    <tr>
                                        <td>...</td>
                                        <td>...</td>
                                        <td>...</td>
                                    </tr>
                                    <tr>
                                        <td>...</td>
                                        <td>...</td>
                                        <td>...</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
import CardWidget from '@/components/dashboard/CardWidget.vue'
import CemeteryApi from '@/services/CemeteryApi';

export default {
    components: {
        CardWidget
    },
    data(){
        return{
            config:{},
            userInfo:{
                wallet_amount:0
            }
        }
    },
    methods:{
        getConfig(){
            CemeteryApi.getConfig()
            .then((res)=>{
                if(res.data.status){
                    this.config=res.data.result
                }else{
                    this.$toast.error(res.data.result.message, {position: 'bottom'});
                } 
            })
        },
        getUserInfo(){
            CemeteryApi.userProfileInfo()
            .then(res => this.userInfo = res.data.result);
        },
    },
    mounted(){
        this.getConfig()
        this.getUserInfo()
    }

}
</script>

<style></style>