<template>
  <!-- <div class="router-view-wrapper" :class="{ wrapperMarginExpanded: IsWrapperMarginExpanded, login: !loginStatement }"> -->
    <div :class="stylemaker">
    <div class="container-fluid" v-if="loginStatement">
      <div class="bg-custom-orange rounded shadow-sm mt-3 p-3 mb-3 d-flex align-items-center">
          <strong class="text-light"> {{$t('Dashboard')}} </strong>
          <div :class="getDropDownStyle">
            <language/>
          </div>
      </div>
    </div>
    <router-view></router-view>
  </div>
  <NavigationMenu v-show="loginStatement"  dir="ltr"/>
  <!-- Footer -->
</template>

<script>
import { useRoute } from "vue-router";
import NavigationMenu from '@/components/master/NavigationMenu.vue';
import language from '@/components/master/language.vue';
export default {
  data() {
    return {
      IsWrapperMarginExpanded: false,
      route: useRoute(),
      loginStatement: true, 
    }
  },
  components: {
    NavigationMenu,
    language
  },
  computed:{
    stylemaker(){
      let lang=this.$store.state.language
      if(lang=='en' && this.loginStatement && this.IsWrapperMarginExpanded){
        return "router-view-wrapper-left wrapperMarginLeftExpanded";
      }else if(lang=='en' && this.loginStatement && !this.IsWrapperMarginExpanded){
        return "router-view-wrapper-left wrapperMarginLeftColapsed";
      }if(lang=='ar' && this.loginStatement && this.IsWrapperMarginExpanded){
        return "router-view-wrapper-right wrapperMarginRightExpanded";
      }else if(lang=='ar' && this.loginStatement && !this.IsWrapperMarginExpanded){
        return "router-view-wrapper-right wrapperLeftMarginRightColapsed";
      }
      else{
        return ""
      }
    },
    getDropDownStyle(){
      if(this.$store.state.language=='en'){
        return 'locale-changer me-auto ms-0'
      }else{
        return 'locale-changer ms-auto me-0'
      }
    }
  },
  mounted() {
    console.log('mounted');
    
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
    this.onResize();
    if (this.loginStatement == false) {
      this.IsWrapperMarginExpanded = false;
      this.loginStatement = false;
    }
    else {
      this.loginStatement = true;
    }
    this.$store.dispatch("onStart");
    this.loginStatement=this.$store.state.isAuthenticated;

    /// Localization ///
    this.$i18n.locale=this.$store.state.language;
    let htmlEl=document.querySelector("html");
    htmlEl.setAttribute('dir',this.$store.state.language=="en"? "ltr": "rtl");
    htmlEl.setAttribute('lang',this.$store.state.language);
    
  },
  methods: {
    onResize() {
      this.innerWidth = window.innerWidth;
      if (this.innerWidth < 600) {
        this.IsWrapperMarginExpanded = false;
      }
      else {
        this.IsWrapperMarginExpanded = true;
      }
    }
  },
  created() {
    this.emitter.on("sidbarState", (e) => {
      this.IsWrapperMarginExpanded=!e
    });
  },
}
</script>

<style scoped>
.router-view-wrapper-left {
  margin-left: 65px;
}

.router-view-wrapper-right{
  margin-right: 65px;
}

.router-view-wrapper.login {
  margin-left: 0px;
}

.wrapperMarginLeftExpanded {
  margin-left: 290px;
}
.wrapperMarginLeftColapsed {
  margin-left: 65px;
}

.wrapperMarginRightExpanded {
  margin-right: 290px;
}
.wrapperMarginRightColapsed {
  margin-right: 65px;
}
</style>